<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :isLoadingSaveButton="isLoadingSaveButton"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="requestFields"
    @save="handleSaveForm()"
    @cancel="onCancel('SupportRequestsForCurrentUser')"
  >
    <template #waitst>
      <radio-buttons
        v-model="form.waitst"
        :buttons="radioButtons"
      ></radio-buttons>
    </template>
    <template #innerRequest>
      <radio-buttons
        v-model="form.innerRequest"
        :buttons="innerRequests"
      ></radio-buttons>
    </template>

    <template #file>
      <sm-fileinput
        @changeFile="(file) => handleChangeFile(file)"
        @removeFile="handleRemoveFile()"
        class="upload-file-modal__input"
      ></sm-fileinput>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import RadioButtons from '@/components/client-sites/RadioButtons.vue';
import SmFileinput from '@/components/common/forms/SmFileinput.vue';

export default {
  name: 'SupportRequestForCurrentUserCreate',

  components: {
    SmEditableItem,
    RadioButtons,
    SmFileinput
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      isLoadingSaveButton: false,
      controllerName: 'SupportRequestsForCurrentUser',
      pageHeader: 'Создание заявки',
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Мои заявки',
          route: { name: 'SupportRequestsForCurrentUser' },
        },
        {
          text: 'Создание заявки',
        },
      ],
      initialForm: {
        name: null,
        text: null,
        type: null,
        priority: 2,
        cusID: null,
        aluserid: null,
        waitst: null,
        parentId: null,
        statusId: null,
        accID: null,
        innerRequest: null,
        isOnDevelop: false,
        file: null,
      },
      CUS_ID: 95, // Клиент: Системы управления НИЦ
      ACC_ID: 343010, // Аккаунт клиента, от которого необходимо создать заявку: Служебная учетная запись
      G_TYPE: 42, // Тип заявки ГИС: РР - Разработка
      STATUS_ID: 58, // Статус: РР. Открыта (новая)
      form: null,
      fileList: [],
      radioButtons: [
        { id: '0', name: 'Ставить в ожидание ответа от сотрудника' },
        { id: '1', name: 'Создать как подчиненную  для заявки' },
      ],
      innerRequests: [
        { id: '0', name: 'Заявка от клиента' },
        { id: '1', name: 'Внутренняя заявка' },
      ],
    };
  },

  computed: {
    ...mapState({
      priorities: (state) => state.common.priorities,
      types: (state) => state.common.types,
      customers: (state) => state.common.customers,
      gisTypes: (state) => state.common.gisTypes,
      initiationStatuses: (state) => state.common.initiationStatuses,
      performers: (state) => state.common.performers,
      customerAccounts: (state) => state.common.customerAccounts,
    }),

    requestFields() {
      const fields = this.createBaseFields();

      if (this.form.isOnDevelop) this.setDevelopFields();

      if (this.form.innerRequest === '0') return this.setInternalRequestFields(fields);
      else this.setClientField();

      return fields;
    },
  },

  created() {
    this.isLoadingPage = true;

    const customers = this.getCommonList({ name: 'Customers' });
    const initiationStatuses = this.getCommonList({
      name: 'InitiationStatuses',
    });
    const gisTypes = this.getCommonList({ name: 'GisTypes' });
    const types = this.getCommonList({ name: 'types' });
    const priorities = this.getCommonList({ name: 'priorities' });
    const performers = this.getCommonList({ name: 'performers' });

    Promise.all([
      customers,
      initiationStatuses,
      gisTypes,
      priorities,
      types,
      performers,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  watch: {
    'form.cusID': {
      handler(newVal) {
        if (newVal && newVal !== this.initialForm.cusID) {
          this.getCommonList({
            name: 'CustomerAccounts',
            params: { customerId: this.form.cusID },
          })
        }
      },
    },

    'form.innerRequest': {
      handler(newVal, oldVal) {
        if (newVal && newVal !== oldVal && newVal === '0') {
          this.clearClientFields();
        }
      },
    },
  },

  methods: {
    ...mapActions({
      createItem: 'krisha/addRequest',
      addFileToRequest: 'krisha/addFileToRequest',
      getCommonList: 'common/getCommonList',
    }),

    createBaseFields() {
      return [
        {
          tab: 'Основные',
          groups: [
            {
              groupTitle: '',
              form: [
                {
                  type: 'text',
                  key: 'name',
                  label: 'Название',
                },
                {
                  type: 'checkbox',
                  key: 'innerRequest',
                  label: 'Заявка от клиента',
                },
                {
                  type: 'textarea',
                  key: 'text',
                  label: 'Текст заявки',
                },
                {
                  type: 'select',
                  key: 'type',
                  label: 'Тип заявки',
                  list: this.types.data || [],
                },
                {
                  type: 'select',
                  key: 'priority',
                  label: 'Приоритет заявки',
                  list: this.priorities.data || [],
                },
                {
                  type: 'select',
                  key: 'aluserid',
                  label:
                    'Сотрудник для автоматического перевода после создания',
                  list: this.performers.data,
                },
              ],
            },
            {
              groupTitle: '',
              form: [
                {
                  type: 'checkbox',
                  key: 'isOnDevelop',
                  label: 'Заявка на разработку',
                },

                {
                  type: 'select',
                  key: 'gtype',
                  label: 'Тип заявки ГИС',
                  list: this.gisTypes.data,
                },
                {
                  type: 'radio',
                  key: 'waitst',
                  label: 'Ставить в ожидание ответа от сотрудника',
                },
                {
                  type: 'select',
                  key: 'statusId',
                  label: 'Статус',
                  list: this.initiationStatuses.data,
                },    
                {
                  type: 'select',
                  key: 'parentId',
                  label: 'Родительская заявка (для подчиненных заявок)',
                  list: this.gisTypes.data,
                },
                {
                  type: 'file',
                  key: 'file',
                  label: 'Файл',
                },
              ]
            }
          ]
        },
      ];
    },

    setDevelopFields() {
      this.form.innerRequest = '1';
      this.form.gtype = this.G_TYPE;
      this.form.statusId = this.STATUS_ID;
      
      this.clearClientFields();
    },
    
    setInternalRequestFields(fieldsArr) {
      const newFields = [...fieldsArr];

      newFields[0].groups[0].form.splice(5, 0, {
        type: 'select',
        key: 'cusID',
        label: 'Клиент',
        list: this.customers.data || [],
      });

      newFields[0].groups[1].form.splice(5, 0, {
        type: 'select',
        key: 'accID',
        label: 'Аккаунт клиента, от которого необходимо создать заявку',
        list: this.customerAccounts.data || [],
      });

      return newFields;
    },

    setClientField() {
      this.form.cusID = this.CUS_ID;
      this.form.accID = this.ACC_ID;
    },

    clearClientFields() {
      this.form.cusID = null;
      this.form.accID = null;
    },

    handleChangeFile(files) {
      if (!files || files.length === 0) return;
      
      this.isUploadFile = true;

      for (const file of files) {
        if (file instanceof File) {
          let formData = new FormData();

          formData.append('file', file, file.name);
  
          this.fileList.push(formData);
        } else {
          this.$notify({
            header: `Некорректный файл: ${file.name || file.type}`,
            type: 'error',
            timer: 5000,
          });
        }
      }

      this.isUploadFile = false;
    },

    handleRemoveFile() {
      this.fileList = [];
    },

    uploadFiles(params) {
      let uploadPromise = Promise.resolve();

      for (const item of this.fileList) {
        uploadPromise = uploadPromise.then(() => {
          const data = {
            formData: item,
            params
          };

          return this.addFileToRequest(data)
            .catch((error) => {
              this.$notify({
                text: error,
                type: 'error',
                timer: 5000
              });

              console.error('Ошибка загрузки файла:', error);
            });
        });
      }

      return uploadPromise;
    },

    addFileHandler(requestId) {
      const params = {
        reqID: requestId,
        isHidden: 1,
        doRequestUnreaded: 1
      };

      this.uploadFiles(params)
        .then(() => {
          this.$notify({
            header: `Заявка №${requestId} успешно создана`,
            type: 'success',
            timer: 5000,
          });

          this.$router.push({
            name: 'SupportRequestInfo',
            id: requestId,
          });
        })
        .catch((error) => {
          this.isLoadingSaveButton = false;

          this.$notify({
            text: error,
            type: 'error',
            timer: 5000
          });
        });
    },

    handleSaveForm() {
      this.isLoadingSaveButton = true;

      this.form.waitst = Number(this.form.waitst);
      this.form.innerRequest = Number(this.form.innerRequest);

      const formWithoutDevelop = { ... this.form };
      delete formWithoutDevelop.isOnDevelop;

      if (this.form.type === null) formWithoutDevelop.type = 19;

      this.createItem(formWithoutDevelop).then((result) => {
        if (!result.data) return;

        const requestId = result.data;

        if (this.fileList.length > 0) {
          this.addFileHandler(requestId);

          return;
        } 

        this.$notify({
          header: `Заявка №${requestId} успешно создана`,
          type: 'success',
          timer: 5000,
        });

        this.$router.push({
          name: 'SupportRequestInfo',
          id: requestId,
        });
      }).catch((error) => {
        this.isLoadingSaveButton = false;
        
        this.$notify({
          text: error,
          type: 'error',
          timer: 5000
        });
      });
    }
  },
};
</script>

<style lang="scss"></style>
